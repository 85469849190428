<template>
    <div>
        <van-nav-bar title="STC钱包" class="custom-nav-bar">
            <!-- 自定义右侧内容 -->
            <template #right>
                <img src="@/assets/images/doubt.svg" alt="Right" class="custom-icon" @click="onRightClick" />
            </template>
        </van-nav-bar>
        <div class="image-div">
            <img class="main-image" src="@/assets/images/main.png" alt="">
            <img class="wallet-image" src="@/assets/images/create.png" alt="Left" @click="createWallet"/>
            <img class="wallet-image" src="@/assets/images/recover.png" alt="Left" @click="recoverWallet"/>
        </div>
    </div>
</template>

<script>
export default {
    name: "WalletView",
    data() {
        return {

        }
    },
    methods: {
        onRightClick() {

        },
        // 创建钱包
        createWallet() {    // 导航到创建钱包页面
            this.$router.push('/home');
        },
        // 恢复钱包
        recoverWallet() {    // 导航到恢复钱包页面

        }
    }
}
</script>

<style scoped>
.image-div {
    margin-top: 160px;
}

.main-image {
    height: 150px;
    width: 192px;
}

.wallet-image {
    height: 68px;
    width: 324px;
    margin-top: 15px;
}

</style>
