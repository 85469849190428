<template>
    <div>
        <van-nav-bar title="转账 STC (StarCoin)" class="custom-nav-bar">
            <!-- 自定义左侧内容 -->
            <template #left>
                <img src="@/assets/images/return.svg" alt="Left" class="custom-icon" @click="onClickLeft" />
            </template>
        </van-nav-bar>
        <div class="form-div">
            <div class="form">
                <div class="form-item">
                    <label class="form-label">STC地址</label>
                    <div class="input-group">
                        <input v-model="address" type="text" placeholder="输入" class="input" />
                        <div class="input-buttons">
                            <button @click="onPaste" class="button">粘贴</button>
                            <img src='@/assets/images/card/scanning.svg' alt="自定义图标">
                        </div>
                    </div>
                </div>

                <div class="form-item">
                    <label class="form-label">数量</label>
                    <div class="input-group">
                        <input v-model="amount" type="number" class="input" />
                        <div class="input-buttons">
                            <button @click="onFillAll" class="button">全部</button>
                        </div>
                    </div>
                </div>
                    <div class="amount-in-cny">≈ ¥{{ amountInCNY }}</div>
                    <button class="next-button" disabled>下一步</button>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    data() {
        return {
            address: '',
            amount: '',
        };
    },
    computed: {
        amountInCNY() {
            // 此处为USDT到CNY的转换逻辑
            return (this.amount * 6.5).toFixed(2); // 示例汇率
        },
    },
    methods: {
        onClickLeft() {
            // 返回按钮逻辑
            this.$router.go(-1);
        },
        onPaste() {
            // 粘贴按钮逻辑
        },
        onFillAll() {
            // 填充全部逻辑
        },
        onSubmit() {
            // 提交表单逻辑
        },
    },
};
</script>

<style>
.form-div {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}

.form {
    margin-top: 10px;
    width: 340px;
}

.form-item {
    margin-bottom: 16px;
}

.form-label {
    text-align: left;
    display: block;
    margin-bottom: 8px;
    color: #606266;
    font-weight: 400;
}

.input-group {
    display: flex;
    align-items: center;
    background-color: #f7f8fa;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
}

.input {
    flex: 1;
    padding: 10px;
    border: none;
    background-color: transparent;
    outline: none;
}

.input-buttons {
    display: flex;
    align-items: center;
}

.button {
    padding: 0 12px;
    border: none;
    background-color: transparent;
    color: #1989fa;
    cursor: pointer;
}

.icon-button {
    font-size: 16px;
}

.amount-in-cny {
    text-align: left;
    margin: 10px 0;
    color: #888;
}

.next-button {
    width: 100%;
    padding: 12px 0;

    /*background-color: #eaeefb;*/
    background: #2953FF;
    border-radius: 10px 10px 10px 10px;
    border: none;
    cursor: not-allowed;
    margin-top: 300px
}
</style>
