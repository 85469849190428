<template>
    <div>
        <van-row class="grid-row" type="flex" justify="center" align="middle">
            <van-col class="grid-col" span="5" @click=" navigateToTransfer">
                <img src='@/assets/images/middle/payment.svg' alt="自定义图标" class="grid-icon">
                <span>转账</span>
            </van-col>
            <van-col class="grid-col" span="5" @click="navigateToCollection">
                <img src='@/assets/images/middle/collection.svg' alt="自定义图标" class="grid-icon">
                <span>收款</span>
            </van-col>
            <van-col class="grid-col" span="5">
                <img src='@/assets/images/middle/c2c.svg' alt="自定义图标" class="grid-icon">
                <span>C2C</span>
            </van-col>
            <van-col class="grid-col" span="5">
                <img src='@/assets/images/middle/financial.svg' alt="自定义图标" class="grid-icon">
                <span>理财</span>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: "HomeMiddle",
    methods: {
        navigateToTransfer() {
            this.$router.push('/transfer'); // 导航到收款页面
        },
        // 路由到收款组件
        navigateToCollection() {
            this.$router.push('/collection'); // 导航到收款页面
        }
    }
}
</script>

<style scoped>
.grid-col {
    display: flex;
    flex-direction: column; /* 子元素垂直排列 */
    align-items: center; /* 水平居中 */
    justify-content: center; /* 垂直居中 */
    margin-top: 10px;
    cursor: pointer; /* 鼠标悬停时显示为指针 */
}

.grid-icon {
    width: 48px; /* 设置宽度 */
    height: 48px; /* 设置高度 */
    display: block;
    margin-bottom: 5px; /* 图标和文字间距 */
}
</style>
