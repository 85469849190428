import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import CollectionView from '@/views/collction/CollectionView.vue'
import TransferView from '@/views/transfer/TransferView.vue'
import WalletView from '@/views/WalletView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/wallet'
  },
    // 钱包创建恢复
  {
    path: '/wallet',
    name: 'WalletView',
    component: WalletView
  },
  {
    path: '/home',
    name: 'HomeView',
    component: HomeView
  },
    // 转账组件
  {
    path: '/transfer',
    name: 'TransferView',
    component: TransferView
  },
    // 收款组件
  {
    path: '/collection',
    name: 'CollectionView',
    component: CollectionView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
