<template>
    <div>
        <van-nav-bar title="首页" left-arrow>
            <!-- 自定义左侧内容 -->
            <template #left>
                <img :src="configIcon" alt="Left" class="custom-icon" @click="onLeftClick" />
            </template>
        </van-nav-bar>
        <div class="account-card">
            <!-- 账户信息卡片 -->
            <AccountCard></AccountCard>
        </div>
        <!-- 中间功能 -->
        <HomeMiddle></HomeMiddle>
        <van-tabbar v-model="active">
            <van-tabbar-item v-for="(tab, index) in tabs" :key="index" :badge="tab.badge">
                <span>{{ tab.name }}</span>
                <template #icon="props">
                    <img :src="props.active ? tab.icon.active : tab.icon.inactive" alt=""/>
                </template>
            </van-tabbar-item>
        </van-tabbar>
        <div class="message-card">
            <div class="message-content"></div>
        </div>
        <!--- 转账记录 --->
        <TransferRecord></TransferRecord>
    </div>
</template>

<script>
import AccountCard from "@/views/home/AccountCard.vue";
import HomeMiddle from "@/views/home/HomeMiddle.vue";
import TransferRecord from "@/views/home/TransferRecord.vue";

export default {
    name: 'HomeView',
    components: {     // 注册组件
        AccountCard,
        HomeMiddle,
        TransferRecord
    },
    data() {
        return {
            tabsActive: 1,
            active: 0,
            configIcon: require('@/assets/images/config.svg'),
            tabs: [
                {
                    name: '首页',
                    badge: '3',
                    icon: {
                        active: require('@/assets/images/tabbar/active/home.svg'),
                        inactive: require('@/assets/images/tabbar/inactive/home.svg'),
                    },
                },
                {
                    name: '兑换',
                    badge: '3',
                    icon: {
                        active: require('@/assets/images/tabbar/active/exchange.svg'),
                        inactive: require('@/assets/images/tabbar/inactive/exchange.svg'),
                    },
                },
                {
                    name: '发现',
                    badge: '3',
                    icon: {
                        active: require('@/assets/images/tabbar/active/discover.svg'),
                        inactive: require('@/assets/images/tabbar/inactive/discover.svg'),
                    },
                },
                {
                    name: '浏览器',
                    badge: '3',
                    icon: {
                        active: require('@/assets/images/tabbar/active/browser.svg'),
                        inactive: require('@/assets/images/tabbar/inactive/browser.svg'),
                    },
                },
            ],
        };
    },
    methods: {
        onLeftClick() {
            // 左侧图片点击事件
            console.log('左侧图片点击');
        },
    }
};
</script>

<style scoped>
.custom-icon {
    width: 20px;
    height: 20px;
}

.account-card {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
}

.card-top img {
    margin-right: 5px; /* 设置图片之间的间距 */
}

.card-top img:last-child {
    margin-right: 0; /* 去除最后一张图片的右间距 */
}

.message-card {
    display: flex;
    justify-content: center; /* 水平居中 */
    align-items: center; /* 垂直居中 */
    margin-top: 10px;
}

.message-content {
    border-radius: 8px; /* 设置圆角 */
    width: 360px; /* 可以根据需要调整 */
    height: 83px; /* 可以根据需要调整 */
    background-image: url('@/assets/images/message.png');
    background-size: cover; /* 确保背景图片覆盖整个容器 */
    background-position: center; /* 图片居中显示 */
}

</style>
