<template>
    <div>
        <van-nav-bar title="收款" class="custom-nav-bar">
            <!-- 自定义左侧内容 -->
            <template #left>
                <img src="@/assets/images/return.svg" alt="Left" class="custom-icon" @click="onLeftClick" />
            </template>
            <!-- 自定义右侧内容 -->
            <template #right>
                <img src="@/assets/images/doubt.svg" alt="Right" class="custom-icon" @click="onRightClick" />
            </template>
        </van-nav-bar>

        <van-notice-bar style="font-size: 12px; border-radius: 12px; margin-left: 16px;
        margin-right: 16px;"
                        left-icon="info-o"
                        wrapable
                        :scrollable="false"
                        text="仅向该地址发送Starcoin(STC)网络资产。其它资产将永远丢失。"
        />
        <div><p class="stc-text">STC</p></div>
        <div class="qr-code-container">
            <!-- 二维码图片 -->
            <div>
                <img src="@/assets/images/qr-code.png" alt="QR Code" class="qr-code"/>
            </div>
            <div class="address">
                <p>0x3c61f0e4BA200e9F8997bC5914f070d9527EDA01</p>
            </div>
        </div>
        <van-row type="flex" justify="center" gutter="2" style="margin-top: 30px;">
            <van-col span="6">
                <div>
                    <div class="custom-right-content">
                        <div class="custom-div">
                            <img src="@/assets/images/doubt.svg" alt="Right" class="custom-icon" @click="onRightClick" />
                        </div>
                        <p style="font-size: 14px;">复制</p>
                    </div>
                </div>
            </van-col>
            <van-col span="6">
                <div>
                    <div class="custom-right-content">
                        <div class="custom-div">
                            <img src="@/assets/images/doubt.svg" alt="Right" class="custom-icon" @click="onRightClick" />
                        </div>
                        <p style="font-size: 14px;">设置金额</p>
                    </div>
                </div>
            </van-col>
            <van-col span="6">
                <div>
                    <div class="custom-right-content">
                        <div class="custom-div">
                            <img src="@/assets/images/share.svg" alt="Right" class="custom-icon" @click="onRightClick" />
                        </div>
                        <p style="font-size: 14px;">分享</p>
                    </div>
                </div>
            </van-col>
        </van-row>
    </div>
</template>

<script>
export default {
    name: 'CollectionView',
    methods: {
        onLeftClick() {
            // 左侧图片点击事件 - 返回到上一页
            this.$router.go(-1);
        },
        onRightClick() {
            // 右侧图片点击事件
            console.log('右侧图片点击');
        }
    }
};
</script>

<style scoped>
.custom-icon {
    width: 20px;
    height: 20px;
}

/* 更具体的选择器，确保样式生效 */
.custom-nav-bar .van-nav-bar__left,
.custom-nav-bar .van-nav-bar__right,
.custom-nav-bar .van-nav-bar__title {
    border: none !important;
    box-shadow: none !important;
}

.custom-right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.qr-code-container {
    background-color: #FFFFFF;
    box-shadow: 2px 4px 8px 2px rgba(0,0,0,0.1);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 260px; /* 设置容器的高度 */
    width: 220px; /* 设置容器的宽度 */
    margin: auto; /* 使容器居中 */
    padding: 20px; /* 可选：设置内边距 */
}

.custom-div {
    width: 50px;
    height: 50px;
    background: #F4F5F7;
    border-radius: 40px 40px 40px 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stc-text {
    margin-top: 0px;
    font-weight: bold;
    margin-bottom: 0px; /* 设置STC文本与二维码之间的下边距 */
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 16px; /* 可选：设置字体大小 */
}

.qr-code {
    width: 200px;
    height: 200px;
    margin: 10px 0; /* 设置二维码的上下边距 */
    border-radius: 12px; /* 设置圆角 */
}

.address {
    text-align: center; /* 可选：使地址文本居中 */
    width: 100%;
    word-break: break-all; /* 强制单词换行 */
    overflow: hidden; /* 隐藏溢出的内容 */
    text-overflow: ellipsis; /* 使用省略号表示溢出的文本 */
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    margin-top: 0px; /* 设置地址文本与二维码之间的上边距 */
    font-size: 14px;
}

.address p {
    margin: 0;
}
</style>
