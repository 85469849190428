<template>
    <div>
        <van-tabs style="margin-top: 2px" v-model="tabsActive">
            <van-tab title="全部"></van-tab>
            <van-empty description="暂无数据" />
            <van-tab title="支出"></van-tab>
            <van-tab title="收入"></van-tab>
            <van-tab title="自定义"></van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    name: "TransferRecord"
}
</script>

<style scoped>

</style>
