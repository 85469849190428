<template>
    <div>
        <div class="card-content">
            <div class="card-top">
                <img src='@/assets/images/card/eye.svg' alt="自定义图标">
                <img @click="scanCode" src='@/assets/images/card/scanning.svg' alt="自定义图标">
                <img src='@/assets/images/card/notify.svg' alt="自定义图标">
            </div>
            <div class="card-middle">
                <div class="amount">
                    <span class="amount-value">123.342,62</span>
                    <span class="currency-type">STC</span>
                </div>
                <span class="currency">￥10.718</span>
                <div class="address">
                    <span>TRqY8QugR9abUpTttR37NFVx8uKH9tBBUn</span>
                    <img src='@/assets/images/card/copy.svg' alt="自定义图标">
                </div>
            </div>
        </div>

        <!-- 扫描二维码组件 -->
        <van-popup v-model="popShow" position="bottom" :style="{ height: '92%' }"  @opened="initQrScanner" @closed="closeQrScanner">
            <van-nav-bar title="扫描二维码" class="custom-nav-bar">
                <!-- 自定义左侧内容 -->
                <template #left>
                    <img src="@/assets/images/return.svg" alt="Left" class="custom-icon" @click="onLeftClick" />
                </template>
            </van-nav-bar>
            <div id="reader" style="width: 100%; height: 100%;"></div>
            <p>{{ qrCodeContent }}</p>
        </van-popup>
    </div>
</template>

<script>
import { Html5Qrcode } from "html5-qrcode";

export default {
    name: "AccountCard",
    data() {
        return {
            popShow: false,
            qrCodeContent: "",
            html5QrCode: null
        };
    },
    methods: {
        scanCode() {
            this.popShow = true;
        },
        async initQrScanner() {
            // 检查浏览器是否支持相机功能
            if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
                alert("您的浏览器不支持相机功能");
                return;
            }

            this.html5QrCode = new Html5Qrcode("reader");
            try {
                await this.html5QrCode.start(
                    { facingMode: "environment" }, // 使用后置摄像头
                    {
                        fps: 10,    // 每秒扫描的帧数
                        qrbox: 250  // 设置扫描框的大小
                    },
                    this.onScanSuccess,
                    this.onScanFailure
                );
            } catch (err) {
                console.error(`无法启动扫描: ${err}`);
                alert("无法启动扫描，请检查相机权限设置并重试。");
            }
        },
        closeQrScanner() {
            if (this.html5QrCode) {
                this.html5QrCode.stop().then(() => {
                    console.log("QR Code扫描已停止");
                }).catch(err => {
                    console.error("无法停止扫描:", err);
                });
            }
        },
        onScanSuccess(decodedText, decodedResult) {
            this.qrCodeContent = decodedText;
            console.log(`扫描结果: ${decodedText}`, decodedResult);
            this.popShow = false; // 关闭弹出框
            this.closeQrScanner(); // 停止扫描
        },
        onScanFailure(error) {
            console.warn(`QR Code扫描错误: ${error}`);
        }
    }
};
</script>

<style scoped>
.card-content {
    border-radius: 8px; /* 设置圆角 */
    width: 360px; /* 可以根据需要调整 */
    height: 140px; /* 可以根据需要调整 */
    background-image: url('@/assets/images/background-card.svg');
    background-size: cover; /* 确保背景图片覆盖整个容器 */
    background-position: center; /* 图片居中显示 */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.card-top {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end; /* 右对齐 */
    margin-right: 15px;
}

.card-top img {
    margin-right: 10px; /* 设置图片之间的间距 */
}

.card-top img:last-child {
    margin-right: 0; /* 去除最后一个图片的右间距 */
}

.card-middle {
    display: flex;
    flex-direction: column; /* 垂直排列 */
    align-items: flex-start; /* 左对齐 */
    padding: 0 15px; /* 两边内边距 */
    margin-bottom: 30px;
}

.amount {
    display: flex;
    align-items: baseline; /* 使两个 span 底部对齐 */
    gap: 5px; /* 设置两个 span 之间的间距 */
    margin-bottom: 5px; /* 调整与 .currency 之间的间距 */
}

.amount-value {
    font-family: "Andale Mono", serif;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 28px;
}

.currency-type {
    font-family: "Andale Mono", serif;
    font-weight: bold;
    font-size: 16px;
    color: rgb(207, 215, 222);
    line-height: 19px;
}

.currency {
    font-family: "Andale Mono", serif;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 0; /* 取消 margin-top 以紧贴 .amount */
}

.address {
    display: flex;
    align-items: center;
    margin-top: 20px;
    gap: 5px; /* 设置 span 和 img 之间的间距 */
}

.address span {
    font-family: "Andale Mono", serif;
    font-weight: 400;
    font-size: 12px;
    color: rgb(207, 215, 222);
    line-height: 16px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}
</style>
